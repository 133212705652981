import React from "react";
import axios from "axios";
import styled from "@emotion/styled";
import {
  MantineProvider,
  Container,
  SimpleGrid,
  Text,
  Flex,
  Button,
} from "@mantine/core";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import SearchBar from "./SearchTechs/SearchBar";
import TechnicianCard from "./SearchTechs/TechnicianCard";

function Gagan() {
  const [didUserSearch, setDidUserSearch] = React.useState(false);
  const [searchedTechs, setSearchedTechs] = React.useState([]);
  const [searchTechValues, setSearchTechValues] = React.useState({
    searchRole: "",
    location: "",
  });
  const [isLoading, setIsloading] = React.useState(true);

  const urlParams = new URLSearchParams(window.location.search);
  const locationParam = urlParams.get("location");
  const searchRoleParam = urlParams.get("role");

  const searchTechs = (searchRole, location) => {
    setIsloading(true);
    setDidUserSearch(true);
    // https://conneq.retool.com/workflows/ca1f436e-6d71-4a66-a907-7a94f273695c
    axios
      .post(
        "https://api.retool.com/v1/workflows/ca1f436e-6d71-4a66-a907-7a94f273695c/startTrigger?workflowApiKey=retool_wk_670683b79ff84a28a8ec8b42bef80903",
        { searchRole, location, sendSlackNotification: true }
      )
      .then((res) => {
        setDidUserSearch(true);
        setIsloading(false);
        setSearchedTechs(res.data.techs);
      })
      .catch(() => {
        setIsloading(false);
        window.alert("Error searching technicians");
      });
  };

  React.useEffect(() => {
    setIsloading(true);
    if (locationParam && searchRoleParam) {
      searchTechs(searchRoleParam, locationParam);
      return;
    }
    if (locationParam === null || searchRoleParam === null) {
      // https://conneq.retool.com/workflows/3821c8b7-6355-48ab-912c-00b7b2897cee
      axios
        .post(
          "https://api.retool.com/v1/workflows/3821c8b7-6355-48ab-912c-00b7b2897cee/startTrigger?workflowApiKey=retool_wk_54b51bdf78774ce7adb884edd88afdbc",
          { featuredTechs: ["rgarza", "torg", "jberger"] }
        )
        .then((res) => {
          setIsloading(false);
          setSearchedTechs(res.data.featuredTechs);
        })
        .catch(() => {
          setIsloading(false);
          window.alert("Error fetching featured technicians");
        });
    }
  }, []);

  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <Main>
        <Container size="lg" pt={40}>
          <SearchBar
            searchRoleValue={searchRoleParam}
            locationValue={locationParam}
            onSearch={(searchRole, location) => {
              setSearchTechValues({ searchRole, location });
              searchTechs(searchRole, location);
            }}
          />
          <Flex style={{ visibility: !didUserSearch ? "hidden" : "visible" }}>
            <Text size={20} weight={600} color="#fff" mt={20}>
              Search Results
            </Text>
          </Flex>
          <SimpleGrid
            mt={16}
            cols={3}
            verticalSpacing="xl"
            breakpoints={[
              { maxWidth: "76em", cols: 2, spacing: "md" },
              { maxWidth: "62em", cols: 2, spacing: "sm" },
              { maxWidth: "48em", cols: 1, spacing: "sm" },
            ]}
          >
            {isLoading &&
              [1, 2, 3].map((_, index) => (
                <TechnicianCard
                  key={index}
                  isLoading={true}
                  didUserSearch={didUserSearch}
                />
              ))}
            {!isLoading &&
              searchedTechs.map((data) => {
                if (!data) return null;
                return <TechnicianCard key={data.id} tech={data} />;
              })}
          </SimpleGrid>

          <Flex justify="center" align="center" pt={30}>
            {isLoading ? (
              <Skeleton width={250} height={30} borderRadius={10} />
            ) : (
              <Button
                size="xs"
                bg="#00f85d"
                c="#3e4066"
                styles={(theme) => ({
                  root: {
                    borderRadius: "0.625rem",
                    ":hover": {
                      backgroundColor: theme.fn.lighten("#00f85d", 0.55),
                    },
                  },
                })}
                onClick={() =>
                  (window.location.href = "https://www.conneq.com/get-started")
                }
              >
                {didUserSearch
                  ? `Need more ${searchTechValues.searchRole}s?`
                  : "Need multiple freelancers?"}
              </Button>
            )}
          </Flex>
        </Container>
      </Main>
    </MantineProvider>
  );
}

const Main = styled.main`
  background: transparent;
  /* background: black; */
  color: white;
  /* width: 100vw;
  min-height: 40vw; */
  text-decoration: none;
`;

export default Gagan;
