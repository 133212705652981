import React from "react";
import styled from "@emotion/styled";
import {
  IconMapPin,
  IconStarFilled,
  IconGps,
  IconArrowRight,
} from "@tabler/icons-react";
import {
  Avatar,
  BackgroundImage,
  Button,
  Flex,
  Paper,
  Stack,
  Text,
  Anchor,
} from "@mantine/core";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import RenderBackgroundImage from "./RenderBackgroundImage";

const TechnicianCard = (data) => {
  if (data.isLoading)
    return (
      <Card>
        {!data.didUserSearch && (
          <OpenIcon>
            <Text
              mr={3}
              style={{
                display: "flex",
                justifyContent: "center",
                opacity: "100%",
              }}
            >
              <IconStarFilled height={16} color="#fff" />
              Featured
            </Text>
          </OpenIcon>
        )}
        <CardContents>
          <Stack spacing="sm" align="center">
            <BackgroundImage h={108} radius="md">
              <Skeleton height={108} />
            </BackgroundImage>
            <Paper>
              <Avatar
                radius="xl"
                // tt="uppercase"
                size={128}
                mt={-75}
                style={{ position: "relative", zIndex: 2 }}
                styles={{
                  root: { border: "0.5rem solid white", borderRadius: "50%" },
                  placeholder: { border: "0", borderRadius: "50%" },
                  placeholderIcon: { border: "0", borderRadius: "50%" },
                }}
              >
                <Skeleton circle height={128} />
              </Avatar>
            </Paper>
            <Flex direction="column" align="center">
              <Text size={20} weight={600} color={"#3e4066"} tt="capitalize">
                <Skeleton width={100} />
              </Text>
              <Flex align="center">
                <Text size={14} fw={500} ml={3} color={"#6a6d93"}>
                  <Skeleton width={150} />
                </Text>
              </Flex>
              <Flex align="center" pt={8} mb={8}>
                <Skeleton width={100} />
              </Flex>
            </Flex>
          </Stack>
        </CardContents>
      </Card>
    );

  return (
    <Card>
      {data.tech.isFeatured && (
        <OpenIcon>
          <Text
            mr={3}
            style={{
              display: "flex",
              justifyContent: "center",
              opacity: "100%",
            }}
          >
            <IconStarFilled height={16} color="#fff" />
            Featured
          </Text>
        </OpenIcon>
      )}
      <CardContents>
        <Stack spacing="sm" align="center">
          <BackgroundImage
            h={108}
            src={RenderBackgroundImage(data.tech.location)}
            radius="md"
          />
          <Paper style={{ position: "relative" }}>
            <Avatar
              radius="xl"
              color="primary"
              tt="uppercase"
              size={128}
              mt={-75}
              src={data.tech.profilePictureUrl}
              style={{ position: "relative" }}
              styles={{
                root: { border: "0.5rem solid white", borderRadius: "50%" },
                placeholder: { border: "0", borderRadius: "50%" },
                placeholderIcon: { border: "0", borderRadius: "50%" },
              }}
            >
              {data.tech.firstName[0]}
              {data.tech.lastName[0]}
            </Avatar>
          </Paper>
          <Flex direction="column" align="center">
            <Text size={20} weight={600} color={"#3e4066"} tt="capitalize">
              {data.tech.firstName} {data.tech.lastName}
            </Text>
            <Flex align="center">
              <IconMapPin height={18} color={"#6a6d93"} />
              <Text size={14} fw={500} ml={3} color={"#6a6d93"}>
                {data.tech.location}
              </Text>
            </Flex>
            {data.tech.isWillingToTravel && (
              <WillingToTravelBadge>
                <Text
                  mr={3}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    opacity: "100%",
                  }}
                >
                  <IconGps height={18} />
                  Willing to travel
                </Text>
              </WillingToTravelBadge>
            )}
            <Anchor
              href={`https://app.conneq.com/u/${data.tech.slug}?utm_source=homepage`}
              alt={`View ${data.tech.firstName} profile`}
              rel="noopener noreferrer"
              target="_blank"
              my={12}
            >
              <Button
                size="lg"
                variant="default"
                rightIcon={<IconArrowRight width="1.5rem" />}
                style={{ border: "1.5px solid #E1E1E7" }}
                styles={{
                  root: {
                    color: "#3e4066",
                    borderRadius: "0.625rem",
                  },
                }}
              >
                View Profile
              </Button>
            </Anchor>
          </Flex>
        </Stack>
      </CardContents>
    </Card>
  );
};

export default TechnicianCard;

const Card = styled(Paper)`
  border-radius: 10px;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  &:hover {
    outline: 2px solid rgba(225, 225, 231, 0.5);
    box-shadow: none;
  }
`;

const CardContents = styled.div`
  display: relative;
  padding: 0.625rem;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  a {
    position: relative;
    /* Remove default underline from browser styling  */
    text-decoration: none !important;
  }
`;

const OpenIcon = styled.span`
  background-image: linear-gradient(90deg, #564bcc, #a299ff);
  display: flex;
  align-items: center;
  padding: 0.3rem 0.3rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #564bcc;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1rem;

  svg {
    fill: #fff;
    margin-right: 3px;
  }
`;

const WillingToTravelBadge = styled.span`
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  align-items: center;
  padding: 0.1rem 0.2rem;
  border-radius: 20px;
  background-color: #dbe2eb;
  border: 1.25px solid #dbe2eb;
  color: #564bcc;
  font-size: 12px;
  font-weight: 600;
  line-height: 1rem;
  opacity: 0.8;
`;
