import React from "react";
import styled from "@emotion/styled";
import {
  Container,
  Grid,
  Select,
  Button,
  em,
  getBreakpointValue,
} from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { SERVICE_REGIONS, GIG_ROLES } from "./utilities";

const SearchBar = ({ onSearch, locationValue = "", searchRoleValue = "" }) => {
  const [searchRole, setSearchRole] = React.useState(searchRoleValue);
  const [location, setLocation] = React.useState(locationValue);

  const sericeRegionKeyValuePairs = [
    { value: "la", label: "Los Angeles Metro" },
    { value: "sf", label: "SF Bay Area" },
    { value: "nyc", label: "NYC Metro" },
    { value: "mia", label: "Miami/South Florida" },
    { value: "phx", label: "Phoenix" },
    { value: "las", label: "Las Vegas" },
    { value: "san", label: "San Diego" },
    { value: "orl", label: "Orlando/Central Florida" },
    { value: "nash", label: "Nashville" },
    { value: "chi", label: "Chicagoland" },
    { value: "dc", label: "Washington DC" },
    { value: "atl", label: "Atlanta" },
    { value: "dfw", label: "DFW Metroplex" },
    { value: "nola", label: "New Orleans" },
    { value: "bos", label: "Boston/New England" },
  ];

  return (
    <SearchContainer size="lg" p={15} pt={25} mb={40}>
      <Grid
        grow
        sx={(theme) => ({
          [`@media (max-width: ${em(
            getBreakpointValue(theme.breakpoints.md) - 1
          )})`]: {
            flexDirection: "column",
          },
        })}
      >
        <Grid.Col span={5}>
          <Select
            size="lg"
            placeholder="Search Role..."
            styles={{
              input: {
                border: "1px solid #dbe2eb",
                borderRadius: "0.625rem",
              },
            }}
            searchable
            data={GIG_ROLES.sort()}
            value={searchRole}
            onChange={(e) => {
              setSearchRole(e);
            }}
            icon={<IconSearch />}
          />
        </Grid.Col>
        <Grid.Col span={5}>
          <Select
            placeholder="Select Service Region"
            size="lg"
            searchable
            data={SERVICE_REGIONS}
            value={location}
            onChange={(e) => {
              setLocation(e);
            }}
            styles={{
              input: {
                border: "1px solid #dbe2eb",
                borderRadius: "0.625rem",
              },
            }}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <Button
            size="lg"
            w="100%"
            bg="#00f85d"
            c="#3e4066"
            onClick={() => {
              if (searchRole === "Stage Hand" && location !== "") {
                window.location.href = `https://www.conneq.com/stage-hand-landing?loc=${
                  sericeRegionKeyValuePairs.find((s) => s.label === location)
                    .value
                }`;
                onSearch(searchRole, location);
                return;
              }
              if (searchRole !== "" && location !== "")
                onSearch(searchRole, location);
            }}
            styles={(theme) => ({
              root: {
                borderRadius: "0.625rem",
                ":hover": {
                  backgroundColor: theme.fn.lighten("#00f85d", 0.55),
                },
              },
            })}
          >
            Search
          </Button>
        </Grid.Col>
      </Grid>
    </SearchContainer>
  );
};

const SearchContainer = styled(Container)`
  outline-offset: 4px;
  background-color: #fff;
  border-radius: 1rem;
  outline: 2px solid rgba(255, 255, 255, 0.4);
  padding: 1.25rem;
`;

export default SearchBar;
